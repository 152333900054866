<template>
  <div>
    <b-card>
      <own-table
        ref="returnSaleTable"
        name-table="return-sales"
        :table-columns="columns"
        :disable-create-btn="true"
        :use-custom-filter="true"
        :custom-query-filter="queryFilter"
        sort-by="created_at"
        sort-direction="desc"
        @searchFieldOnChange="searchOnChange"
      >
        <template #cell(transaction)="data">
          <div v-if="data.data.item.transaction">
            <b-avatar
              :id="`invoice-row-${
                data.data.item.transaction
                  ? data.data.item.transaction.no_invoice
                  : ''
              }`"
              size="32"
              :variant="`light-${
                resolveInvoiceStatusVariantAndIcon(
                  data.data.item.transaction.status
                ).variant
              }`"
            >
              <feather-icon
                :icon="
                  resolveInvoiceStatusVariantAndIcon(
                    data.data.item.transaction.status
                  ).icon
                "
              />
            </b-avatar>
            <b-tooltip
              :target="`invoice-row-${data.data.item.transaction.no_invoice}`"
            >
              <p class="mb-0">
                {{ data.data.item.transaction.status }}
              </p>
            </b-tooltip>
          </div>
          <div v-else>
            <span>-</span>
          </div>
        </template>
        <template #cell(transaction_date)="data">
          {{
            data.data.item.transaction
              ? formatDate(data.data.item.transaction.created_at)
              : '-'
          }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusOrderVariant[data.data.item.status]">
            <span>{{ statusOrder[data.data.item.status] }}</span>
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <feather-icon
            :id="`branch-row-${data.data.item.no_invoice}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="
              () =>
                $router.push({
                  name: 'return_sales_detail',
                  params: { id: data.data.item.id },
                })
            "
          />
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import { BCard, BBadge, BTooltip, BAvatar } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import vSelect from 'vue-select'
import { formatDate, title } from '@core/utils/filter'
import { statusOrder, statusOrderVariant } from '@core/utils/constant'
import useReturnSale from './useReturnSale'
// import FilterFields from '../components/filter/Filter.vue'
// import calonAgenStoreModule from './calonAgenStoreModule'

export default {
  components: {
    BCard,
    // FilterFields,
    OwnTable,
    BBadge,
    BTooltip,
    BAvatar,
  },
  setup() {
    // const CALON_AGEN_STORE_MODULE = 'calon-agen'
    // if (!store.hasModule(CALON_AGEN_STORE_MODULE)) {
    // 	store.registerModule(CALON_AGEN_STORE_MODULE, calonAgenStoreModule)
    // }
    // onUnmounted(() => {
    // 	store.unregisterModule(CALON_AGEN_STORE_MODULE)
    // })
    // const caloAgenTable = ref(null)
    // const regionAPiUrl = ref('https://permohonancustomer.ingco.co.id/public/api')

    const { resolveInvoiceStatusVariantAndIcon } = useReturnSale()
    const filtersField = ref([
      {
        key: 'provinsi',
        type: 'select',
        model: null,
        opitons: [],
      },
      {
        key: 'kota',
        type: 'select',
        model: null,
        opitons: [],
      },
      {
        key: 'from_date',
        type: 'date',
        model: null,
      },
      {
        key: 'to_date',
        type: 'date',
        model: null,
      },
    ])
    const columns = ref([
      {
        label: 'Doc. Number',
        key: 'number',
      },
      {
        label: 'Tgl. Return',
        key: 'created_at',
        sortable: true,
        formatter: val => formatDate(val),
      },
      {
        label: 'No. Invoice/Order',
        key: 'order.transaction.no_invoice',
      },
      {
        label: 'Status',
        key: 'status',
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const queryFilter = ref('')
    const search = ref('')
    const searchOnChange = val => {
      console.log(val)
      search.value = val
      queryFilter.value = filtersField.value
        .map(x => (x.model ? `&${x.key}=${x.model}` : ''))
        .join('')
        .concat(`${val !== '' ? `&q=${val}` : ''}`)
    }

    return {
      filtersField,
      columns,
      queryFilter,
      searchOnChange,
      resolveInvoiceStatusVariantAndIcon,
      formatDate,
      statusOrder,
      statusOrderVariant,
    }
  },
}
</script>
